@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  min-height: 100vh;
  position: relative;
  padding-bottom: 60px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-light {
  color: #cfcfcf !important;
}

.background-dark {
  background-color: #2c2c2c;
}

.hero {
  overflow: hidden;
  position: relative;
  max-height: 800px;
}
.heroImg {
  margin-top: -300px;
}

@media only screen and (max-width: 1023px) {
  .hero {
    overflow: hidden;
    position: relative;
    max-height: 60vh;
  }
  .heroImg {
    margin-top: 0;
  }
}
